import { OddsDisplay } from "@/components/FormattedOdds/common";
import Cookies from "js-cookie";
import { gql } from "@apollo/client";
import { differenceInMinutes } from "date-fns";
import CountriesEnum from "@/constants/CountriesEnum";
import { USStateEnum, CAStateEnum } from "@/constants/StateEnum";
import { CommonFilterType, KeyOfPages } from "@/components/Common/Filters/commonFilterType";
import { BlockedBetsBetType } from "@/components/BlockedBets/common";

const USER_SETTING_COOKIE = "userSetting";

export const FETCH_USER_SETTING = gql`
  query GetUser {
    user {
      user {
        userId
        settings
      }
    }
  }
`;

export type FetchUserSetting = {
  user: {
    user: {
      userId: string;
      settings: string;
    };
  } | null;
};

export const SAVE_USER_SETTING = gql`
  mutation SaveUserSetting($settings: String) {
    user {
      user(settings: $settings)
    }
  }
`;

export type SaveUserSetting = {
  user: {
    user: boolean;
  };
};

export type SaveUserSettingVariables = {
  settings?: string;
};



export type PageFilterProfileType = {
  name: string;
  value: CommonFilterType | null;
  filterIcon: string;
  color: string;
  overriddenBy?: string | null;
};

// when adding any value here for pages, add in KeyOfPages also in commonFilterType.ts
export type PageFilters = {
  arbitrages: PageFilterProfileType[];
  middles: PageFilterProfileType[];
  freebet: PageFilterProfileType[];
  lowhold: PageFilterProfileType[];
  expectedvalue: PageFilterProfileType[];
  "odds-screen": PageFilterProfileType[];
  game: PageFilterProfileType[];
};

export type LocationType = {
  region: USStateEnum | CAStateEnum | undefined;
  country: CountriesEnum | undefined;
};

export type Filters = {
  sportsBookSortId?: number[]
  sportsBookEnums?: string[];
  oddsDisplay: OddsDisplay;
  showPauseFilter?: boolean;
  location?: LocationType;
  isCollapsed?: boolean;
  hideAdvanceFilter?: boolean;
  hideAdvanceFilterDialog?: boolean;
  newSportsBookAdded?: Partial<Record<number, boolean>>;
  authenticatorLoginEnable?: boolean;
};

export type UserSettingsType = {
  pageFilter: PageFilters;
  globalSettings: Filters;
  blockedBets?: Partial<Record<KeyOfPages, BlockedBetsBetType[]>>;
};

export function updateUserSettingTime() {
  const dateTime = new Date();
  Cookies.set(USER_SETTING_COOKIE, JSON.stringify(dateTime));
}

export function isSettingExpired() {
  try {
    const dateTime = JSON.parse(Cookies.get(USER_SETTING_COOKIE)!) as Date;
    return differenceInMinutes(new Date(), dateTime) > 1;
  } catch (error) {
    return true;
  }
}
