import { useTheme } from "@/theme/ThemeContext";
import { Dialog, DialogTitle, DialogActions } from "@mui/material";
import React from "react";
import { StyledModalButton } from "../PageFilter/common";

type LogoutDialogProps = {
  isOpenLogoutDialog: boolean;
  setOpenLogoutDialog: (val: boolean) => void;
  logoutUser: () => void;
};


function LogoutDialog(props: LogoutDialogProps) {
  const { isOpenLogoutDialog, setOpenLogoutDialog, logoutUser } = props;
  const theme = useTheme();
  const handleClose = () => {
    setOpenLogoutDialog(false);
  };

  return (
    <Dialog
      open={isOpenLogoutDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.modal.background,
          border: `1px solid ${theme.palette.modal.border}`,
          backgroundImage: "none", boxShadow: "none",
          borderRadius: "30px",
          minWidth: "300px",
          backdropFilter: "blur(4px)",
          padding: "20px",
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">Are you sure you want to Logout?</DialogTitle>
      <DialogActions sx={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "10px",
        width: "100%",
        gap: 2,
        px: 2,
      }}>
        <StyledModalButton variant="outlined" color="secondary" onClick={logoutUser}>
          Yes
        </StyledModalButton>
        <StyledModalButton variant="contained" color="secondary" onClick={handleClose}>
          No
        </StyledModalButton>
      </DialogActions>
    </Dialog>
  );
}

export default LogoutDialog;
