import { Box, Modal, Stack, Typography, styled, useTheme, useMediaQuery } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import React, { ComponentProps, useState } from "react";
import Image from "next/image";
import { useTranslations } from "next-intl";
import AvailablePlansModal from "@/components/AvailablePlansModal/AvailablePlansModal";
import { LoginType } from "./common";
import LoginPopupEmailSection from "./LoginPopupEmailSection";

const StyledModal = styled(Modal)`
  display: flex;
  backdrop-filter: blur(4px);
`;


export const StyledBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    flex-direction: row;
    background-color: ${theme.palette.modal.background};
    border: 1px solid ${theme.palette.grayScale.twelevepercent};
    border-radius: ${theme.spacing(6)};
    height: max-content;
    margin: auto;
    position: relative;
    width: 918px;
    height: 700px;
    overflow: hidden;
    ${theme.breakpoints.down("md")} {
      max-width: 425px;
       height: 550px;
      width: calc(100% - ${theme.spacing(3)});
      padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
    }
  `,
);

type LoginPopupProps = {
  loginType?: LoginType;
  onClose: () => void;
  email?: string;
} & Omit<ComponentProps<typeof Modal>, "children" | "onClose">;

function LoginPopup({ onClose: onCloseProp, loginType = LoginType.LOG_IN, email, ...restProps }: LoginPopupProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [success, setSucess] = useState(false);
  const [showPlansModal, setShowPlansModal] = useState(false);

  const onModalClose = () => {
    onCloseProp?.();
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const t = useTranslations("Login.LoginPopup");

  return (
    <>
      <StyledModal onClose={onModalClose} {...restProps}>
        <StyledBox alignSelf="center">
          {
            !isMobile && (
              <Stack direction="row" alignItems="center" justifyContent="center" position="relative" flex={1}>
                <Box position="absolute" top={0} left={0} sx={{
                  borderRadius: "30px",
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}>
                  <Image
                    src="/images/login_modal_image.jpeg"
                    alt="login-popup-bg"
                    layout="fill"
                    objectFit="cover"
                  />
                </Box>
              </Stack>
            )
          }

          <Box display="flex" flexDirection="column" position="relative" flex={1} py={isMobile ? 2 : 10} px={isMobile ? 2 : 6}>
            <LoginPopupEmailSection
              loginType={LoginType.LOG_IN}
              onClose={onCloseProp}
              setSucess={setSucess}
              email={email}
              setShowPlansModal={setShowPlansModal}
            />

            <Box position="absolute" pr={isMobile ? 2 : 7} pb={isMobile ? 0 : 2} bottom={0} right={0} display="flex" justifyContent="center" alignItems="center">

              <MailOutlineIcon sx={{ fontSize: 20, color: "text.secondary", mr: 1 }} />
              <Typography variant="label" color="text.secondary">
                {t("support_email")}
              </Typography>
            </Box>
          </Box>

        </StyledBox>
      </StyledModal>
      {showPlansModal && (
        <StyledModal open={showPlansModal} onClose={() => {
          setShowPlansModal(false);
          window.location.reload();
        }}>
          <AvailablePlansModal />
        </StyledModal>
      )}
    </>
  );
}

export default LoginPopup;