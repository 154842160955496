import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Icon, Stack, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useAppSelector } from "@/store/store";
import isNil from "@/utis/isNil";
import SensorsIcon from "@mui/icons-material/Sensors"
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useUser } from "@/user/UserContext";
import { toolOrder, toolDisplayNames, ToolEnum } from "@/user/UserRestrictions/common";
import { PackageTypeEnum } from "@/user/common";
import { useRouter } from "@/navigation";
import { TranslationValues, useFormatter, useTranslations } from "next-intl";
import useFetchUserPaymentPortal from "@/hooks/useFetchUserPaymentPortal";
import { GET_PAYMENT_URL, GetPaymentUrl, GetPaymentUrlVariables, getPackageName, AvailablePlansProps } from "../AvailablePlans/common";
import ErrorBoundaryPopup from "../ErrorBoundary/ErrorBoundaryPopup";
import StyledTypography from "../StyledComponents/EllipseTypography";
import SucessPopup from "../SucessPopup";

const StyledStackMain = styled(Stack)<{ isCurrentPlan: boolean }>(({ theme, isCurrentPlan }) => ({
    position: 'relative',
    border: `1px solid ${theme.palette.grayScale.twelevepercent}`,
    background: theme.palette.customBackground.cards,
    borderRadius: "16px",
    boxShadow: "none",
    marginBottom: "16px",
    width: 282,
    height: "fit-content",
}));

const StyledStackHeader = styled(Stack)<{ isCurrentPlan: boolean }>(({ theme, isCurrentPlan }) => ({
    justifyContent: "space-between",
    borderRadius: "16px",
    width: '100%',
    margin: '0px',
    gap: "24px",
}));



const PlanBox = styled(Box)(
    ({ theme }) => `
      display: flex;
      justify-content: center;
      flex-direction: column;
      background-color: ${theme.palette.modal.background};
      border: 1px solid ${theme.palette.grayScale.twelevepercent};
      border-radius: ${theme.spacing(2)};
      padding: ${theme.spacing(3)} ${theme.spacing(2)};
      gap: ${theme.spacing(2)};
      margin: auto;
      position: relative;
      width: 1200px;
      overflow: auto;
    height: 90vh;

      ${theme.breakpoints.down("md")} {
      height: fit-content;
        flex-direction: column;
        max-width: 425px;
        width: calc(100% - ${theme.spacing(3)});
        padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
      }
    `,
);

const StyledAccordian = styled(Accordion)(({ theme }) => ({
    border: `1px solid ${theme.palette.grayScale.twelevepercent}`,
    background: theme.palette.customBackground.cards,
    borderRadius: "16px",
    boxShadow: "none",
    marginBottom: "16px",
    overflow: "auto"

}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme, }) => ({
    position: 'relative',
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "16px",
    backgroundClip: "padding-box",
    '.MuiAccordionSummary-content': {
        flexDirection: 'column',
        width: '100%',
        margin: '0px',
        padding: '16px 16px 0px 16px',
        gap: "24px",
    },
}));


const StyledButton = styled(Button, {
    shouldForwardProp: (propName) => propName !== "textColor",
})<{ textColor?: string }>(
    ({ theme, textColor }) => `
    height: 52px;
    font-size: ${theme.typography.body2.fontSize};
    font-weight: bold;
    width: 100%;
    border-radius: 6px;
    &.Mui-disabled {
      color: ${textColor || theme.palette.text.primary};
    }
  `,
);


function getRestrictionsLimitText(limit: number | undefined | null, per?: boolean, text?: string) {
    return (
        <Stack direction="row" alignItems="center" gap={1}>
            <DoneAllIcon sx={{ fontSize: 16, color: "text.secondary" }} />
            <Typography variant="body2" color="text.secondary">
                {text}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {limit}
                {per && "%"}
            </Typography>
        </Stack>
    );
};

const getTrialDaysText = (t: (key: string, values?: TranslationValues) => string, restriction: { price: number | null; trialDays: number | null }) => {
    if (restriction.price === null) {
        return "Indefinite";
    }
    if (isNil(restriction.trialDays)) {
        return "None";
    }
    return t("n_days", { value: restriction.trialDays });
};

function AvailablePlansModal({ ...restProps }: AvailablePlansProps) {
    const user = useUser();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const constants = useAppSelector((state) => state.constantsReducer);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    const router = useRouter();
    const restrictions = constants?.restrictions?.filter((res) => res.package !== "LOGGED_IN");

    const [fetchPaymentUrl, { loading: fetchPaymentUrlLoading }] = useMutation<GetPaymentUrl, GetPaymentUrlVariables>(
        GET_PAYMENT_URL,
    );

    const userHasNoActivePackage =
        isNil(user.data?.activePackageType) ||
        user.data?.activePackageType === PackageTypeEnum.ANONYMOUS ||
        user.data?.activePackageType === PackageTypeEnum.LOGGED_IN;

    const { loading: paymentPortalLoading } = useFetchUserPaymentPortal();

    const redirectUserToPayment = (packageType: PackageTypeEnum) => {
        if (userHasNoActivePackage) {
            fetchPaymentUrl({ variables: { packageType } })
                .then((resData) => {
                    const paymentUrl = resData.data?.user.purchase;
                    if (paymentUrl) {
                        router.push(paymentUrl);
                    }
                })
                .catch((err) => <ErrorBoundaryPopup message={err.message} />);
        }
    };

    const onPackageTypeClick = (packageType: PackageTypeEnum, isCancel?: boolean) => {
        if (packageType === PackageTypeEnum.ANONYMOUS) {
            setShowSuccessPopup(true);
        } else {
            redirectUserToPayment(packageType);
        }
    };

    const [expanded, setExpanded] = React.useState<string | false>();

    useEffect(() => {
        const currPackageIndedx = restrictions?.findIndex(
            (restriction) => (user.data?.activePackageType || PackageTypeEnum.ANONYMOUS) === restriction.package,
        );
        if (currPackageIndedx !== undefined && expanded === undefined) {
            setExpanded(`panel${currPackageIndedx}`);
        }
    }, [expanded, restrictions, setExpanded, user.data?.activePackageType]);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const t = useTranslations("plans");

    const format = useFormatter();

    if (isMobile) {
        return (
            showSuccessPopup ? (
                <SucessPopup />
            ) : (
                <PlanBox>
                    <Stack justifyContent="center" pb={expanded ? undefined : 1}>
                        <StyledTypography variant="karantinaHeader" textAlign="center" fontSize={28}>
                            {t("Pick_Your_plan")}
                        </StyledTypography>
                    </Stack>
                    {restrictions?.map((restriction, i) => {
                        const isCurrentPlan = (user.data?.activePackageType || PackageTypeEnum.ANONYMOUS) === restriction.package;
                        return (
                            <StyledAccordian key={i} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)} disableGutters>
                                <StyledAccordionSummary
                                    aria-controls={`panel${i}d-content`}
                                    id={`panel${i}d-header`}
                                >
                                    <Stack direction="row" justifyContent="space-between" width="100%">
                                        <StyledTypography
                                            variant="karantinaHeader"
                                            fontSize={20}
                                            sx={{ backgroundColor: theme.palette.grayScale.sixpercent, borderRadius: "8px", padding: "8px", alignSelf: 'baseline' }}
                                            textAlign="center"
                                        >
                                            {getPackageName(restriction.package)}
                                        </StyledTypography>

                                        <StyledButton
                                            fullWidth
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            sx={{
                                                width: "140px",
                                            }}
                                            disabled={fetchPaymentUrlLoading || paymentPortalLoading}
                                            onClick={() => onPackageTypeClick(restriction.package, isCurrentPlan)}
                                        >
                                            <Typography variant="label" color="secondary">
                                                Choose plan
                                            </Typography>
                                        </StyledButton>
                                    </Stack>

                                    <Stack>
                                        <Stack direction="row" alignItems="baseline" gap={0.5} pb={1} marginRight="auto"
                                            sx={{ borderBottom: `1px solid ${theme.palette.grayScale.twelevepercent}`, width: "100%" }}>
                                            <Typography variant="body1Bold" fontSize={32}>
                                                ${format.number(restriction.price ?? 0, { currency: "USD", maximumFractionDigits: 0 })}
                                            </Typography>
                                            <Typography variant="label" textTransform="uppercase" color="text.secondary">{t("month")}</Typography>
                                        </Stack>
                                        {!(expanded === `panel${i}`) && (
                                            <Stack direction="row" justifyContent="center" p={1} alignItems="center">
                                                <Typography variant="body2" color="text.secondary">
                                                    See Benefits
                                                </Typography>
                                                <ArrowDropDownIcon color="secondary" />
                                            </Stack>
                                        )}
                                    </Stack>
                                </StyledAccordionSummary>
                                <AccordionDetails>
                                    <Stack pb={1}>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                                            <Typography variant="body2" color="text.secondary">
                                                {t("free_bet_converter")}
                                            </Typography>
                                        </Stack>

                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                                            <Typography variant="body2" color="text.secondary">
                                                {t("pre_live_odds")}
                                            </Typography>
                                            <Icon color="error" sx={{
                                                backgroundColor: theme.palette.grayScale.sixpercent,
                                                borderRadius: "8px"
                                            }}>
                                                <SensorsIcon sx={{ width: "20px" }} />
                                            </Icon>
                                            <Typography variant="body2" color="text.secondary">
                                                {t("Live_data")}
                                            </Typography>
                                        </Stack>


                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                                            <Typography variant="body2" color="text.secondary">
                                                {t("portable_filters")}
                                            </Typography>
                                        </Stack>


                                        <Stack direction="row" alignItems="center" gap={1} key={i}>
                                            <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                                            <Typography variant="body2" color="text.secondary">
                                                {t("trial_included")}:
                                            </Typography>
                                            <Typography variant="body2" color="text.primary" fontWeight="bold">
                                                {getTrialDaysText(t, restriction)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    {toolOrder.map((tool, ind) => {
                                        if (tool === ToolEnum.FREE_BET_CONVERTER) return null;
                                        if (tool === ToolEnum.ODDS_SCREEN) {
                                            return (
                                                <Stack flexDirection="column" key={ind} gap={0.5} pt={1.5} pb={2.5}>
                                                    <Typography variant="label" textTransform="uppercase" color="text.primary">
                                                        {toolDisplayNames[tool]}
                                                    </Typography>
                                                    <Stack direction="row" alignItems="center" gap={1}>
                                                        <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                                                        {i === 0 ? (
                                                            <><Typography variant="body2" color="text.secondary">
                                                                {t("refresh_time")}:
                                                            </Typography><Typography variant="body2" fontWeight="bold" color="text.primary">
                                                                    {t("15_Second_Refresh")}
                                                                </Typography></>
                                                        ) : (
                                                            <><Typography variant="body2" color="text.secondary">
                                                                {t("refresh_time")}:
                                                            </Typography><Typography variant="body2" fontWeight="bold" color="text.primary">
                                                                    {t("2_4_Second_Refresh")}
                                                                </Typography></>
                                                        )}
                                                    </Stack>
                                                </Stack>
                                            );
                                        }
                                        const currRestriction = restriction.restrictions.find((res) => res.toolEnum === tool);
                                        return (
                                            <Stack flexDirection="column" key={ind} gap={0.5} pb={2.5}>
                                                <Typography variant="label" textTransform="uppercase" color="text.primary">
                                                    {toolDisplayNames[tool]}
                                                </Typography>
                                                {getRestrictionsLimitText(currRestriction?.rOILimit, true, t("roi_limit"))}
                                                {getRestrictionsLimitText(currRestriction?.maxResult, false, t("max_results"))}
                                                {getRestrictionsLimitText(currRestriction?.sportbookLimit, false, t("sportsbook_limit"))}
                                                {getRestrictionsLimitText(currRestriction?.leagueLimit, false, t("leagues_limit"))}

                                            </Stack>
                                        );
                                    })}

                                    <Stack gap={1}>
                                        <Typography variant="label" textTransform="uppercase" color="text.primary">
                                            {t("device_limit")}
                                        </Typography>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                                            <Typography variant="body2" color="text.secondary">
                                                {t("device_limit")}:
                                            </Typography>
                                            <Typography variant="body2" color="text.primary" fontWeight="bold">
                                                1
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack width="100%" p={2}>
                                        <StyledButton
                                            fullWidth
                                            variant="outlined"
                                            color={isCurrentPlan ? "error" : "secondary"}
                                            size="small"
                                            sx={{
                                                width: "100%",
                                                height: "36px",
                                            }}
                                            disabled={fetchPaymentUrlLoading || paymentPortalLoading}
                                            onClick={() => onPackageTypeClick(restriction.package, isCurrentPlan)}
                                        >
                                            <Typography variant="body1" color={isCurrentPlan ? "error" : "secondary"}>
                                                Choose plan
                                            </Typography>
                                        </StyledButton>
                                    </Stack>
                                    <Stack direction="row" justifyContent="center" p={1} alignItems="center" onClick={() => setExpanded(expanded === `panel${i}` ? false : `panel${i}`)}
                                        sx={{ borderTop: `1px solid ${theme.palette.grayScale.twelevepercent}` }}>
                                        <Typography variant="body2" color="text.secondary">
                                            Close Benefits
                                        </Typography>
                                        <ArrowDropUpIcon color="secondary" />
                                    </Stack>
                                </AccordionDetails>
                            </StyledAccordian>
                        );
                    })}
                </PlanBox>
            )
        );
    }

    return (
        showSuccessPopup ? (
            <SucessPopup />
        ) :
            (
                <PlanBox>
                    <Stack pb={expanded ? undefined : 1}>
                        <StyledTypography variant="karantinaHeader" textAlign="center" fontSize={28}>
                            {t("Pick_Your_plan")}
                        </StyledTypography>
                    </Stack>
                    <Stack direction="row" justifyContent="center" gap={2} pb={2} overflow="auto">
                        {restrictions?.map((restriction, i) => {
                            const isCurrentPlan = user?.isLoggedIn && (user.data?.activePackageType || PackageTypeEnum.ANONYMOUS) === restriction.package;

                            return (
                                <StyledStackMain key={i} p={2} isCurrentPlan={isCurrentPlan} >
                                    <StyledStackHeader
                                        isCurrentPlan={isCurrentPlan}
                                    >
                                        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                                            <StyledTypography
                                                variant="karantinaHeader"
                                                fontSize={20}
                                                sx={{ backgroundColor: theme.palette.grayScale.sixpercent, borderRadius: "8px", padding: "8px", alignSelf: 'baseline' }}
                                                textAlign="center"
                                            >
                                                {getPackageName(restriction.package)}
                                            </StyledTypography>
                                            {isCurrentPlan && user?.isLoggedIn && <Typography variant="label" textTransform="uppercase" color="text.secondary">
                                                Current plan
                                            </Typography>}
                                        </Stack>
                                        <Stack gap={2}>
                                            <Stack direction="row" alignItems="baseline" gap={0.5} pb={2} marginRight="auto"
                                                sx={{ borderBottom: `1px solid ${theme.palette.grayScale.twelevepercent}`, width: "100%" }}>
                                                <Typography variant="body1Bold" fontSize={32}>
                                                    ${format.number(restriction.price ?? 0, { currency: "USD", maximumFractionDigits: 0 })}
                                                </Typography>
                                                <Typography variant="label" textTransform="uppercase" color="text.secondary">{t("month")}</Typography>
                                            </Stack>
                                            <StyledButton
                                                fullWidth
                                                variant="outlined"
                                                color={isCurrentPlan ? "error" : "secondary"}
                                                size="small"
                                                disabled={fetchPaymentUrlLoading || paymentPortalLoading}
                                                onClick={() => onPackageTypeClick(restriction.package, isCurrentPlan)}
                                            >
                                                <Typography variant="body1" color={isCurrentPlan ? "error" : "secondary"}>
                                                    Choose Plan
                                                </Typography>
                                            </StyledButton>
                                        </Stack>
                                    </StyledStackHeader>
                                    <Stack py={1.5} gap={1}>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                                            <Typography variant="body2" color="text.secondary">
                                                {t("free_bet_converter")}
                                            </Typography>
                                        </Stack>

                                        <Stack direction="row" alignItems="center" gap={0.5}>
                                            <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                                            <Typography variant="body2" color="text.secondary" ml={0.5}>
                                                {t("pre_live_odds")}
                                            </Typography>
                                            <Icon color="error" sx={{
                                                backgroundColor: theme.palette.grayScale.sixpercent,
                                                borderRadius: "8px"
                                            }}>
                                                <SensorsIcon sx={{ width: "20px", mb: "4px" }} />
                                            </Icon>
                                            <Typography variant="body2" color="text.secondary">
                                                {t("Live_data")}
                                            </Typography>
                                        </Stack>


                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                                            <Typography variant="body2" color="text.secondary">
                                                {t("portable_filters")}
                                            </Typography>
                                        </Stack>


                                        <Stack direction="row" alignItems="center" gap={1} key={i}>
                                            <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                                            <Typography variant="body2" color="text.secondary">
                                                {t("trial_included")}:
                                            </Typography>
                                            <Typography variant="body2" color="text.primary" fontWeight="bold">
                                                {getTrialDaysText(t, restriction)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    {toolOrder.map((tool, ind) => {
                                        if (tool === ToolEnum.FREE_BET_CONVERTER) return null;
                                        if (tool === ToolEnum.ODDS_SCREEN) {
                                            return (
                                                <Stack flexDirection="column" key={ind} gap={0.5} pb={1.5}>
                                                    <Typography variant="label" textTransform="uppercase" color="text.primary">
                                                        {toolDisplayNames[tool]}
                                                    </Typography>
                                                    <Stack direction="row" alignItems="center" gap={1}>
                                                        <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                                                        {i === 0 ? (
                                                            <><Typography variant="body2" color="text.secondary">
                                                                {t("refresh_time")}:
                                                            </Typography><Typography variant="body2" fontWeight="bold" color="text.primary">
                                                                    {t("15_Second_Refresh")}
                                                                </Typography></>
                                                        ) : (
                                                            <><Typography variant="body2" color="text.secondary">
                                                                {t("refresh_time")}:
                                                            </Typography><Typography variant="body2" fontWeight="bold" color="text.primary">
                                                                    {t("2_4_Second_Refresh")}
                                                                </Typography></>
                                                        )}
                                                    </Stack>
                                                </Stack>
                                            );
                                        }
                                        const currRestriction = restriction.restrictions.find((res) => res.toolEnum === tool);
                                        return (
                                            <Stack flexDirection="column" key={ind} gap={0.5} pb={1.5}>
                                                <Typography variant="label" textTransform="uppercase" color="text.primary">
                                                    {toolDisplayNames[tool]}
                                                </Typography>
                                                {getRestrictionsLimitText(currRestriction?.rOILimit, true, t("roi_limit"))}
                                                {getRestrictionsLimitText(currRestriction?.maxResult, false, t("max_results"))}
                                                {getRestrictionsLimitText(currRestriction?.sportbookLimit, false, t("sportsbook_limit"))}
                                                {getRestrictionsLimitText(currRestriction?.leagueLimit, false, t("leagues_limit"))}

                                            </Stack>
                                        );
                                    })}

                                    <Stack gap={1}>
                                        <Typography variant="label" textTransform="uppercase" color="text.primary">
                                            {t("device_limit")}
                                        </Typography>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                                            <Typography variant="body2" color="text.secondary">
                                                {t("device_limit")} 1
                                            </Typography>
                                            <Typography variant="body2" color="text.primary" fontWeight="bold">
                                                1
                                            </Typography>
                                        </Stack>
                                    </Stack>

                                    <StyledButton
                                        fullWidth
                                        variant="outlined"
                                        color={isCurrentPlan ? "error" : "secondary"}
                                        size="small"
                                        sx={{
                                            mt: 2,
                                        }}
                                        disabled={fetchPaymentUrlLoading || paymentPortalLoading}
                                        onClick={() => onPackageTypeClick(restriction.package, isCurrentPlan)}
                                    >
                                        <Typography variant="body1" color={isCurrentPlan ? "error" : "secondary"}>
                                            Choose Plan
                                        </Typography>
                                    </StyledButton>
                                </StyledStackMain>
                            );
                        })}
                    </Stack>
                </PlanBox>
            )
    );
}

export default AvailablePlansModal;