import { PaletteType } from "@/theme/common";
import { Box, CircularProgress, useTheme } from "@mui/material";
import Image from "next/image";
import React, { ComponentProps } from "react";

type LogoProgressSpinnerProps = ComponentProps<typeof CircularProgress>;

function LogoProgressSpinner({ size = "70px", ...restProps }: LogoProgressSpinnerProps) {
  const theme = useTheme();

  return (
    <Box width={size} height={size} sx={{ position: "relative" }}>
      <Image
        src={theme.palette.mode === PaletteType.Dark ? `/P-logo-circle-dark.png` : `/P-logo-circle-light.png`}
        alt="Pick The Odds"
        fill
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        style={{ padding: "10%" }}
      />
      <CircularProgress
        size={size}
        thickness={3}
        {...restProps}
        sx={{
          position: "absolute",
          zIndex: 1,
          color: "text.primary",
          animationDuration: "2s",
          ...restProps.sx,
        }}
        {...restProps}
      />
    </Box>
  );
}

export default LogoProgressSpinner;
