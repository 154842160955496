export enum OddsDisplay {
  AMERICAN = "AMERICAN",
  DECIMAL = "DECIMAL",
  FRACTIONAL = "FRACTIONAL",
  PROBABILITY = "PROBABILITY",
}


export const validateDecimalOdds = (value: string): boolean => {
  const decimalValue = parseFloat(value);
  return /^\d+\.\d{2}$/.test(value) && decimalValue > 1.00;
};

export const validateFractionalOdds = (value: string): boolean =>
  /^\d+\/\d+$/.test(value) && !/[.+-]/.test(value);

export const validateProbabilityOdds = (value: string): boolean => {
  const probabilityValue = parseFloat(value);
  return /^\d{1,2}\.\d{2}$/.test(value) && probabilityValue >= 0 && probabilityValue <= 100;
};

export const validateAmericanOdds = (value: string): boolean => {
  const parsedValue = parseInt(value, 10);
  return !Number.isNaN(parsedValue) && parsedValue >= -20000 && parsedValue <= 20000 && !/[/.]/.test(value);
};