import { Button, IconButton, Stack, styled, TextField, ToggleButton, Typography } from '@mui/material';

export const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.customBackground.cards,
  border: `1px solid ${theme.palette.grayScale.twelevepercent}`,
  borderRadius: "16px",
  padding: 32,
  gap: 16,
  [theme.breakpoints.down('md')]: {
    padding: 16
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => `
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    display: none;
  }
  background: ${theme.palette.grayScale.twelevepercent};
  border-radius: 8px;
  width: 100%;
  input {
    height: 32px;
    font-size: 14px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`);

export const StyledToggleButton = styled(ToggleButton)(
  ({ theme }) => `
  text-transform: none;
  width: 100%;
  padding: 8px;
  font-size: 14px;
  MuiToggleButton-root.Mui-disabled{
  border: none;
}
`,
);

export const StyledButton = styled(Button)(
  ({ theme }) => `
  text-transform: none;
  width: fit-content;
  padding: 6px;
  border-radius: 8px;
  border: none;
  transition: none;
  background-color: ${theme.palette.grayScale.sixpercent};
 :hover {
 background-color: ${theme.palette.grayScale.sixpercent};
 transform: none;
}
  '
`,
);


interface StyledTypographyProps {
  selected?: boolean;
}

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<StyledTypographyProps>(({ theme, selected }) => `
  text-transform: none;
  color: ${!selected ? theme.palette.text.secondary : "inherit"};
`);



export const NumberTextField = styled(TextField, {
  shouldForwardProp: (propName) => propName !== "warning",
})<{ warning?: boolean }>(
  ({ warning, theme }) => `
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    display: none;
  }
  .MuiInputBase-root {
    border: ${warning ? `1px solid ${theme.palette.warning.light}` : undefined}
  }
  input {
    caret-color: ${warning ? "transparent" : undefined};
      height: 32px;
      font-size: 14px;
  }
  background: ${theme.palette.grayScale.twelevepercent};
  border-radius: 8px;
    .MuiOutlinedInput-input{
    width:auto;
    }
      & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`,
);


export const StyledModalButton = styled(Button, {
  shouldForwardProp: (propName) => propName !== "textColor",
})<{ textColor?: string }>(
  ({ theme, textColor }) => `
  height: 44px;
  font-size: ${theme.typography.caption.fontSize};
  font-weight: bold;
  width: 180px;
   padding: ${theme.spacing(0)};
  ${theme.breakpoints.down("md")} {
    padding: ${theme.spacing(0)};
    width: 120px;
  }
  &.Mui-disabled {
    color: ${textColor || theme.palette.text.primary};
  }
`,
);

export const StyledIconButton = styled(IconButton)(
  ({ theme }) => `
    position: absolute;
    background-color: ${theme.palette.grayScale.sixpercent};
    top: ${theme.spacing(2)};
    right: ${theme.spacing(2)};
  `,
);

export const emptyFilter = (setweight: (val: string | number) => void) => {
  setweight("");
};
export const onNumberInputBlur = (weight: string | number, weightProps: string | number, setweight: (val: string | number) => void) => {
  if (weight === "") {
    setweight(weightProps);
  }
};