import React, { useRef, useState, useEffect } from "react";
import { ApplyButtonLong } from "@/components/SelectMenu/SelectMenuApply";
import { useMutation } from "@apollo/client";
import { TurnstileInstance } from "@marsidev/react-turnstile";
import { Box, Stack, TextField, Typography, useMediaQuery, useTheme, Checkbox } from "@mui/material";
import separateErrorCodeAndMssgFromError from "@/utis/getErrorCodeFromMessage";
import { useTranslations } from "next-intl";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LogoProgressSpinner from "@/components/LogoProgressSpinner";
import CustomSquareIcon from "@/components/Icons/CustomSquareIcon";
import {
  UserSignup,
  UserSignupVariables,
  USER_SIGNUP,
  generateHashKey,
  LoginType,
  LoginAuthenticationMethodEnum,
} from "./common";
import LoginVericationCode, { StyledTurnstile } from "./LoginVericationCode";


export type LoginPopupEmailSectionProps = {
  loginType?: LoginType;
  onClose: () => void;
  setSucess: (val: boolean) => void;
  email?: string;
  setShowPlansModal: (val: boolean) => void;
};

function LoginPopupEmailSection({
  loginType,
  onClose: onCloseProp,
  setSucess,
  email: emailProps,
  setShowPlansModal,
}: LoginPopupEmailSectionProps) {
  const [emailAddress, setEmailAddress] = useState<string>(emailProps || "");
  const [emailError, setEmailError] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string>();
  const [hashCode, setHashCode] = useState<string>("");
  const [saveEmail, setSaveEmail] = useState<boolean>(false);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const turnStileRef = useRef<TurnstileInstance>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [verificationType, setverificationType] = useState<LoginAuthenticationMethodEnum>();
  const [handleUserSignup, { loading, error, data }] = useMutation<UserSignup, UserSignupVariables>(USER_SIGNUP, {
    onCompleted: async (resData) => {
      setverificationType(resData.user.signup);
      setSucess(true);
    },
    onError: () => turnStileRef.current?.reset(),
  });

  useEffect(() => {
    const savedEmail = localStorage.getItem("savedEmail");
    if (savedEmail) {
      setEmailAddress(savedEmail);
      setSaveEmail(true);
    }

    const handleFocus = () => {
      if (emailInputRef.current) {
        emailInputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };

    const inputElement = emailInputRef.current;
    if (inputElement) {
      inputElement.addEventListener('focus', handleFocus);
      inputElement.focus();
      inputElement.blur();
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', handleFocus);
      }
    };
  }, []);

  const handleVerify = (captchaTokenApi: string) => {
    setCaptchaToken(captchaTokenApi);
  };

  const signInClicked = () => {
    const emailAddressError = !/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(emailAddress);
    if (emailAddressError) {
      setEmailError(true);
      return;
    }
    const newHashCode = generateHashKey();
    setHashCode(newHashCode);
    handleUserSignup({
      variables: {
        email: emailAddress,
        cfTurnstileToken: captchaToken!,
        hashCode: newHashCode,
      },
    });
    if (saveEmail) {
      localStorage.setItem("savedEmail", emailAddress);
    } else {
      localStorage.removeItem("savedEmail");
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value.trim();
    setEmailAddress(email);
    if (!emailError) return;
    if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handleSaveEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSaveEmail(event.target.checked);
  };

  const disableSinupBtn = emailAddress.trim().length === 0 || emailError || !captchaToken || loading;
  const t = useTranslations("Login.LoginPopup");

  function getLoginBtnContent() {
    if (loading) {
      return <LogoProgressSpinner />;
    }
    if (data) {
      return <CheckCircleIcon />;
    }
    if (loginType === LoginType.LOG_IN) {
      return t("login");
    }
    return t("sign_up");
  }

  return (
    <>
      {!data && (
        <>
          {error ? (
            <Typography variant="body2" color="error" mb={2} mt={2}>
              {separateErrorCodeAndMssgFromError(error.message)?.[1]}
            </Typography>
          ) : (
            <Stack direction="column" gap={isMobile ? 2 : 8}>
              <Typography variant="body1Bold" color="text.primary" fontSize={30}>
                {t("welcome")}
              </Typography>
              <Typography variant="body2" fontSize={20} mb={2} mt={2} color="text.primary">
                {t("login_or_signup")}
              </Typography>
            </Stack>
          )}
          <TextField
            inputRef={emailInputRef}
            fullWidth
            error={emailError}
            placeholder="Email Address"
            onChange={handleEmailChange}
            disabled={loading}
            size="small"
            autoComplete="on"
            type="email"
            sx={{
              input: {
                pt: 1.5,
                pb: 1.5,
                fontSize: theme.typography.body3.fontSize,
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: "none",
                backgroundColor: theme.palette.grayScale.sixpercent,
              },
            }}
            InputProps={{
              inputProps: { maxLength: 200 },
              startAdornment: <MailOutlineIcon sx={{ fontSize: 20, color: "text.secondary", mr: 1 }} />,
            }}
            value={emailAddress}
            variant="outlined"
          />
          <Stack direction="row" alignItems="center" mt={0.5} ml={0.5}>
            <Checkbox checked={saveEmail} onChange={handleSaveEmailChange} disableTouchRipple disableFocusRipple disableRipple icon={<CustomSquareIcon sx={{
              fontSize: 16,
              m: 0.5,
            }} />} sx={{
              ":checked": {
                fontSize: 16,
              },
            }} />
            <Typography variant="label">Save my email</Typography>
          </Stack>

          <Box height={48} my={2} border="none">
            <StyledTurnstile
              ref={turnStileRef}
              siteKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
              options={{
                theme: theme.palette.mode,
                retry: "never",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                size: "flexible" as any,
              }}
              sx={{
                width: "100%",
                border: "none",
              }}
              onSuccess={handleVerify}
            />
          </Box>
          <ApplyButtonLong
            color="secondary"
            variant="contained"
            disabled={disableSinupBtn}
            onClick={signInClicked}
            size="large"
            sx={{ height: 56, mt: 3 }}
          >
            {getLoginBtnContent()}
          </ApplyButtonLong>

          {emailError && (
            <Stack mt={2} sx={{
              backgroundColor: theme.palette.error.dark,
              borderRadius: "8px",
              padding: "12px",
              justifyContent: "center",
            }}>
              <Typography variant="body2" color="error">
                {t("invalid_email")}
              </Typography>
              <Typography variant="label" color="error">
                {t("invalid_email_des")}
              </Typography>
            </Stack>
          )}
        </>
      )}
      {data && (
        <LoginVericationCode
          hashCode={hashCode}
          emailAddress={emailAddress}
          onClose={onCloseProp}
          setShowPlansModal={setShowPlansModal}
          verificationType={verificationType!}
        />
      )}
    </>
  );
}

export default LoginPopupEmailSection;