import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function CustomSquareIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            {...props}
        >
            <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="4.5"
                stroke="#ECECEC"
                fill="none"
            />
        </SvgIcon>
    );
}

export default CustomSquareIcon;
