import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function MobileCheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={24} height={40.848} viewBox="0 0 24 40.848" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.104 25.152 5.88 20.856l1.44 -1.44 2.784 2.856 7.104 -7.248 1.44 1.44zm-6.864 15.696q-1.392 0 -2.304 -0.936T0 37.584V3.312Q0 1.92 0.936 0.936 1.848 0 3.24 0h17.544q1.392 0 2.28 0.936Q24 1.872 24 3.312v34.272q0 1.392 -0.936 2.376 -0.936 0.936 -2.304 0.936zm-1.248 -7.152h19.992V7.128H1.992z" />
    </SvgIcon>
  );
}

export default MobileCheckIcon;
